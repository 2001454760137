import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Achievement1 from "./../images/achievement/musanze-youth-center.pdf"
import Achievement2 from "./../images/achievement/assessment-of-medical-and-legal-systems-on-operationalization-of-exemptions-for-abortion.pdf"
import Achievement3 from "./../images/achievement/assessment-of-the-availability-of-medical-abortion-commodities-in-rwanda.pdf"
import Achievement4 from "./../images/achievement/family-planning-integration-and-reproductive-health-services.pdf"
import Achievement5 from "./../images/achievement/moving-from-law-to-policy-and-practice-the-case-of-making-abortion-safer-in-rwanda.pdf"
import Achievement6 from "./../images/achievement/provision-of-abortion-for-victims-of-gender-based-violence-in-rwanda.pdf"
import Achievement7 from "./../images/achievement/RHIYW-Brochure-V3.pdf"
import Achievement8 from "./../images/achievement/RHIYW-Organizational-Profile-Updated.pdf"
import Achievement9 from "./../images/achievement/elargir-acces-aux-services-de-so-nsapres.pdf"
import Achievement10 from "./../images/achievement/expanding-access-to-postabortion-care.pdf"
import Achievement11 from "./../images/achievement/expanding-access-to-postabortion-care-english.pdf"
import Achievement12 from "./../images/achievement/innovation-through-telemedecine-to-improuve-medical-abortion-access.pdf"
import Achievement13 from "./../images/achievement/it-is-not-them.pdf"
import Teamimage from "./../images/achievement.jpg"

const SecondPage = () => (
  <Layout>
    <SEO title="Our Achievements" />
    <section
      className="section-header text-white overlay-soft-primary"
      style={{
        backgroundImage: "url(" + Teamimage + ")",
        backgroundColor: "#cccccc",
        backgroundPosition: "top",
        backgroundRepeat: "center",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div className="container-fluid">
        <div className="row justify-content-center text-center align-items-center min-vh-50">
          <div className="col-xl-8 col-lg-9 col-md-10">
            <h1 className="display-1">Our Achievements</h1>
          </div>
        </div>
      </div>
    </section>
    <section className="pt-2 pt-md-4">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-10 pb-6">
            {/* Table */}
            <div className="table-responsive">
              <table className="table table-flush table-clickable mb-0">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th className="text-right">Download</th>
                  </tr>
                </thead>
                <tbody>
                  <tr data-href="pricing.html">
                    <td className="table-clickable-hover">RHiYW Brochure</td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement7} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3" />{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="pricing.html">
                    <td className="table-clickable-hover">
                      RHIYW Organizational Profile
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement8} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3" />{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="pricing.html">
                    <td className="table-clickable-hover">Outreach</td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement1} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3" />{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="pricing.html">
                    <td className="table-clickable-hover">
                      Abortion Service Provision in Rwanda Since the Publication
                      of Exemptions in 2012: An Assessment of Medical and Legal
                      systems
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement2} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3" />{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="#!">
                    <td className="table-clickable-hover">
                      Assessment of the Availability of Medical Abortion
                      Commodities in Rwanda
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement3} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3" />{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="#!">
                    <td className="table-clickable-hover">
                      Integrating family planning and reproductive health
                      services in primary health care: the Rwandan model.
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement4} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3" />{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="#!">
                    <td className="table-clickable-hover">
                      Provision of abortion for victims of gender-based violence
                      in Rwanda: Challenges due to stigma and lack of evidence
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement6} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3" />{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="#!">
                    <td className="table-clickable-hover">
                      Moving from Law to Policy and Practice: The Case of Making
                      Abortion Safer in Rwanda
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement5} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3" />{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="#!">
                    <td className="table-clickable-hover">
                      Combining structural and reproductive health to improve
                      transition to adulthood: The Musanze Youth Center model
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement1} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3" />{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="#!">
                    <td className="table-clickable-hover">
                      Élargir l’accès aux services de soins après
                      avortement au Rwanda
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement9} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3"/>{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="#!">
                    <td className="table-clickable-hover">
                      Expanding Access to Postabortion Care
                      Services in Rwanda
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement10} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3"/>{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="#!">
                    <td className="table-clickable-hover">
                      Expanding Access to Postabortion Care
                      Services in Rwanda (English)
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement11} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3"/>{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="#!">
                    <td className="table-clickable-hover">
                      Innovation through telemedecine to improve medication abortion access in Musanze district, Rwanda
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement12} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3"/>{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>
                  <tr data-href="#!">
                    <td className="table-clickable-hover">
                      “It is not them, it is us”: How family planning supply
                      strategies in rural areas can improve demand and
                      utilization of long acting methods
                    </td>
                    <td className="text-right text-nowrap">
                      <a href={Achievement13} target="_blank">
                        <span className="flag-icon flag-icon-us mr-3"/>{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                      </a>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SecondPage
